
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "baseApiUrl": "https://public.dj.whl-staging.com",
  "publicDomain": "https://public.dj.whl-staging.com",
  "publicDomainAgent": "https://agent.dj.whl-staging.com",
  "publicDomainAgentPreview": "https://agent-preview.dj.whl-staging.com",
  "publicDomainPreview": "https://preview.dj.whl-staging.com",
  "adminDomain": "https://admin.dj.whl-staging.com",
  "publicType": "traveller",
  "staticCDN": "https://tripf-static.whl-staging.com",
  "cdnSuffix": "/build/vpre-2.66.3.01",
  "logAPI": "/integration/v1/logs",
  "logWorkerSRC": "/logger.worker.js",
  "siteKeyGreCaptcha": "6Leczs8UAAAAAOvc6jEXJRvFynq-zw0g0ai4vuPg",
  "siteKeyGoogleMapJsAPI": "AIzaSyBTBaHrzzCBiP4TSWUrLmcd9sSfe-xN6Mk",
  "siteKeyFacebook": "486019852015123",
  "heapID_TV": "3702372386",
  "gmtID_TV": "GTM-MWPW8VCJ",
  "intrepidID": "2035",
  "paymentPinENV": "test",
  "paymentKeyUSD": "pk_chrHN_W68zU7kLf4YkTvhw",
  "paymentKeyCAD": "pk_chrHN_W68zU7kLf4YkTvhw",
  "paymentKeyGBP": "pk_chrHN_W68zU7kLf4YkTvhw",
  "paymentKeyEUR": "pk_chrHN_W68zU7kLf4YkTvhw",
  "paymentKeyNZD": "pk_chrHN_W68zU7kLf4YkTvhw",
  "paymentKeyJPY": "pk_chrHN_W68zU7kLf4YkTvhw",
  "paymentKeyAUD": "pk_chrHN_W68zU7kLf4YkTvhw",
  "chatSdkJSUri": "https://tripf-static.whl-staging.com/chat-sdk/vpre-2.66.3.01/main.min.js",
  "chatServerUri": "https://pre-chat-tool.dj.whl-staging.com",
  "chatConnAttempts": "12",
  "publicApiEndpoint": "https://public.dj.whl-staging.com/extranet/v2",
  "fileServiceUri": "/api/upload/v1/uploadMedia",
  "fileServiceUser": "root",
  "fileServicePasswd": "30Q9,hy;8?>axj{e72p",
  "disableBotsIndexing": "1",
  "useEqCaptureMultiSteps": "true",
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
